var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "prices-modal",
      attrs: {
        title: "Special Prices",
        color: "dark",
        show: _vm.active,
        centered: "",
        closeOnBackdrop: false,
      },
      on: {
        "update:show": function ($event) {
          _vm.active = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "CButton",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.active = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.item
        ? [
            _c("div", { staticClass: "alert alert-info p-2" }, [
              _c("h4", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.item[`${_vm.priceable}_name`].toUpperCase())),
              ]),
              _vm.priceable == "menu"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row border-top pt-3",
                    },
                    [
                      _c("h5", { staticClass: "flex-fill my-0" }, [
                        _vm._v(
                          "Delivery Price: " +
                            _vm._s(_vm._f("toCurrency")(_vm.item?.price))
                        ),
                      ]),
                      _c("h5", { staticClass: "flex-fill my-0" }, [
                        _vm._v(
                          "Takeout Price: " +
                            _vm._s(_vm._f("toCurrency")(_vm.item?.toPrice))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "mt-3 mb-2 d-flex justify-content-between" },
              [
                _c(
                  "CButton",
                  {
                    staticClass: "ml-auto",
                    attrs: {
                      disabled: !!_vm.form,
                      color: "success",
                      shape: "pill",
                      variant: "ghost",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getForm()
                      },
                    },
                  },
                  [
                    _c("CIcon", {
                      staticClass: "align-bottom",
                      attrs: { name: "cil-plus" },
                    }),
                    _vm._v(" Add New "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "CCollapse",
              { attrs: { show: !!_vm.form } },
              [
                _c(
                  "CCard",
                  { attrs: { "border-color": "success" } },
                  [
                    _c(
                      "CCardBody",
                      { staticClass: "p-2" },
                      [
                        _vm.showFormSummary
                          ? _c("form-summary", {
                              staticClass: "form-errors alert alert-danger",
                              attrs: {
                                validator: _vm.$v.form,
                                attributes: _vm.attributes,
                              },
                            })
                          : _vm._e(),
                        _vm.form?.discount_type === "discount_price"
                          ? _c(
                              "CAlert",
                              { attrs: { color: "warning animated fadeIn" } },
                              [
                                _c("strong", [_vm._v("Caution!")]),
                                _vm._v(
                                  " Please enter the discount amount, not the new price of the product. "
                                ),
                                _c("i", [
                                  _vm._v(
                                    "For example, if a pizza goes from €6 to €5, enter the discount amount of €1."
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "CForm",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.submit()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group w-100",
                                    class: { "price-type": _vm.form?.id },
                                  },
                                  [
                                    _c("label", { staticClass: "d-block" }, [
                                      _vm._v("Price Type:"),
                                    ]),
                                    _c("CInputRadioGroup", {
                                      attrs: {
                                        name: "price_type",
                                        options: [
                                          {
                                            value: "delivery",
                                            label: "Delivery",
                                          },
                                          {
                                            value: "takeout",
                                            label: "Takeout",
                                          },
                                          {
                                            value: "both",
                                            label: "Both",
                                            disable: true,
                                          },
                                        ],
                                        checked: _vm.$v.form.price_type.$model,
                                        custom: "",
                                        inline: "",
                                      },
                                      on: {
                                        "update:checked": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.price_type,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-md-row",
                                  },
                                  [
                                    _c("CSelect", {
                                      staticClass: "w-100 mr-2",
                                      attrs: {
                                        label: "Discount Type",
                                        placeholder: "Please select..",
                                        value: _vm.$v.form.discount_type.$model,
                                        options: [
                                          {
                                            value: "fixed_price",
                                            label: "Fixed Price",
                                          },
                                          {
                                            value: "discount_price",
                                            label: "Discount Price",
                                          },
                                          {
                                            value: "discount_percentage",
                                            label: "Discount Percentage",
                                          },
                                        ],
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.discount_type,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("CInput", {
                                      staticClass: "w-100",
                                      attrs: {
                                        label: "Price",
                                        placeholder: "0.00",
                                        value: _vm.$v.form.price.$model,
                                        type: "number",
                                        step: "0.01",
                                        pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.price,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend-content",
                                            fn: function () {
                                              return [
                                                _vm.form?.discount_type ===
                                                "discount_percentage"
                                                  ? _c("strong", [_vm._v("%")])
                                                  : _c("CIcon", {
                                                      attrs: {
                                                        name: "cil-euro",
                                                      },
                                                    }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2805367782
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("CSelect", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Day of Week",
                                    placeholder: "Please select..",
                                    value: _vm.$v.form.day_of_week.$model,
                                    options: [
                                      { value: 1, label: "Monday" },
                                      { value: 2, label: "Tuesday" },
                                      { value: 3, label: "Wednesday" },
                                      { value: 4, label: "Thursday" },
                                      { value: 5, label: "Friday" },
                                      { value: 6, label: "Saturday" },
                                      { value: 0, label: "Sunday" },
                                    ],
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.day_of_week,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row flex-gap-1 justify-content-end",
                                  },
                                  [
                                    _c(
                                      "CButton",
                                      {
                                        attrs: { size: "sm", color: "link" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.reset()
                                          },
                                        },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "CButton",
                                      {
                                        attrs: {
                                          disabled: _vm.loading,
                                          type: "submit",
                                          size: "sm",
                                          color: "success",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form?.id ? "Update" : "Add"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("CDataTable", {
              staticClass: "prices-table",
              attrs: {
                hover: "",
                striped: "",
                items: _vm.item?.prices,
                fields: _vm.tableFields,
                loading: _vm.loading,
                noItemsView: {
                  noResults: "No filtering results are available!",
                  noItems: "Special price not found!",
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "price_type",
                    fn: function ({ item }) {
                      return [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("ucfirst")(item.price_type)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "price",
                    fn: function ({ item }) {
                      return [
                        _c("td", [
                          _c("h5", { staticClass: "mb-0" }, [
                            item.discount_type === "fixed_price"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("toCurrency")(item.price))
                                  ),
                                ])
                              : item.discount_type === "discount_price"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        -1 * item.price,
                                        "discount_price"
                                      )
                                    )
                                  ),
                                ])
                              : item.discount_type === "discount_percentage"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        item.price,
                                        "discount_percentage"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "valid_date",
                    fn: function ({ item }) {
                      return [
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment()
                                    .day(item.day_of_week)
                                    .format("dddd")
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "td",
                          { staticClass: "py-2" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  size: "sm",
                                  color: "info",
                                  shape: "pill",
                                  variant: "ghost",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getForm(item)
                                  },
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-align-left" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "CButton",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  size: "sm",
                                  color: "danger",
                                  shape: "pill",
                                  variant: "ghost",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item, index)
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-trash" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                276151170
              ),
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }