var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c("CDataTable", {
        attrs: {
          "clickable-rows": "",
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "table-filter": {
            label: "Filter:",
            placeholder: "...",
            external: true,
            lazy: true,
          },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No linked categories found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "row-clicked": _vm.rowClicked,
          "update:sorter-value": _vm.sorterValue,
          "update:table-filter-value": _vm.tableSearch,
          "update:column-filter-value": _vm.columnFilter,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "select",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    !item.eol
                      ? _c("CInputCheckbox", {
                          attrs: { checked: item._selected, custom: "" },
                          on: { "update:checked": () => _vm.check(item) },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "move_index",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "move-item" },
                  [
                    !item.eol
                      ? _c("CIcon", {
                          staticClass: "align-bottom",
                          attrs: { name: "cil-move", "data-id": item.id },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "index",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.index) + " ")])]
            },
          },
          {
            key: "image",
            fn: function ({ item }) {
              return [
                _c("td", [
                  item.image
                    ? _c("div", { staticClass: "image-container" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "img",
                              rawName: "v-img",
                              value: { src: item.image?.image_url },
                              expression: "{ src: item.image?.image_url }",
                            },
                          ],
                          attrs: {
                            lazy: true,
                            src: item.image?.image_url.replace(
                              "_desktop.",
                              "_mobile."
                            ),
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "action",
                            on: { click: _vm.selectNewImage },
                          },
                          [_vm._v("✎")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "category_name",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.valid_days_of_week?.length
                      ? _c(
                          "CLink",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: "Day constraints",
                                expression: "'Day constraints'",
                              },
                            ],
                            attrs: {
                              to: {
                                name: "EditLinkedCategory",
                                params: { id: item.id },
                              },
                            },
                          },
                          [
                            _c("CIcon", {
                              staticClass: "text-danger",
                              attrs: { name: "cil-warning" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "RestaurantLinkedMenus",
                            params: { restaurant_id: item.restaurant_id },
                            query: { category: item.id },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.category_name) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "menu_count",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        staticClass: "count bg-dark",
                        attrs: {
                          to: {
                            name: "RestaurantLinkedMenus",
                            params: { restaurant_id: item.restaurant_id },
                            query: { category: item.id },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.menus_count) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "note_public",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.note_public) + " ")])]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center unstrikeout" },
                  [
                    !item.eol
                      ? _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: { html: true, content: "Prices" },
                                expression: "{ html: true, content: 'Prices' }",
                              },
                            ],
                            attrs: {
                              size: "sm",
                              color: "success",
                              shape: "pill",
                              variant: item.prices.length ? "" : "ghost",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showPrices(item)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-euro" } })],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "CButton",
                      {
                        staticClass: "enable-item",
                        attrs: {
                          size: "sm",
                          to: {
                            name: "EditLinkedCategory",
                            params: { id: item.id },
                          },
                          color: "info",
                          shape: "pill",
                          variant: "ghost",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                    !item.eol
                      ? _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "danger",
                              shape: "pill",
                              variant: "ghost",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item, index)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-trash" } })],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CModal",
        {
          staticClass: "replicate-category",
          attrs: {
            title: "Replicate Category",
            color: "success",
            show: _vm.replicateCategoryModal,
            closeOnBackdrop: false,
            centered: "",
          },
          on: {
            "update:show": function ($event) {
              _vm.replicateCategoryModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.replicateCategoryModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled: _vm.selectedRestaurant === null,
                        size: "sm",
                        color: "success",
                      },
                      on: { click: _vm.replicateCategory },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-clone" } }),
                      _vm._v(" Replicate "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                {
                  staticClass: "d-block",
                  staticStyle: { "font-weight": "500" },
                },
                [_vm._v("Target Restaurant:")]
              ),
              _c("v-select", {
                staticClass: "v-select-filter",
                attrs: {
                  placeholder: "Select restaurant..",
                  options: _vm.allRestaurants,
                },
                model: {
                  value: _vm.selectedRestaurant,
                  callback: function ($$v) {
                    _vm.selectedRestaurant = $$v
                  },
                  expression: "selectedRestaurant",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center flex-gap-2" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "info",
                          size: "sm",
                          disabled: _vm.showTrashed,
                        },
                        on: { click: _vm.reIndex },
                      },
                      [
                        _c("CIcon", {
                          staticClass: "mb-1",
                          attrs: { name: "cil-list-numbered" },
                        }),
                        _vm._v(" Re-Index "),
                      ],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "success",
                          size: "sm",
                          disabled:
                            _vm.selectedItem === null || _vm.showTrashed,
                        },
                        on: {
                          click: function ($event) {
                            _vm.replicateCategoryModal = true
                          },
                        },
                      },
                      [
                        _c("CIcon", {
                          staticClass: "mb-1",
                          attrs: { name: "cil-clone" },
                        }),
                        _vm._v(" Replicate Category "),
                      ],
                      1
                    ),
                    _c("CInputCheckbox", {
                      attrs: {
                        label: "Show Trashed",
                        custom: "",
                        checked: _vm.showTrashed,
                      },
                      on: {
                        "update:checked": [
                          function ($event) {
                            _vm.showTrashed = $event
                          },
                          _vm.getAllItems,
                        ],
                      },
                    }),
                  ],
                  1
                ),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("ProductPrices", {
        attrs: { item: _vm.selectedItem, priceable: "category" },
        on: {
          "update:item": function ($event) {
            _vm.selectedItem = $event
          },
        },
      }),
      _c("SelectImage", {
        attrs: { imageId: _vm.selectedItem?.image_id },
        on: { update: _vm.selectedNewImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }